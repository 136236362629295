import React from "react"

import { login, isAuthenticated, getProfile } from "../utils/auth"
import Layout from "../components/Layout"
import Alert from 'react-bootstrap/Alert'
// import "../styles/styles.scss"

const Home = () => {
  if(!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()

  return (
    <main>
      <title>Home Page</title>
      <Layout />
      <Alert variant="success" className="welcomeAlert">
        Hello {[user.name]}!
      </Alert>
    </main>

  )
}

export default Home